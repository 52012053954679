<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					나의 리뷰
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub mypage">
			<!-- content -->
            <section class="content">
                <div class="centered centered--large">
                    <div class="centered__wrap">
                        <div class="textbox">
                            <div class="textbox__icon">
                                <img src="@/assets/images/img_warning.png" alt="작성하신 리뷰가 없습니다." style="width:64px">
                            </div>
                            <h3 class="textbox__title">
                                작성하신 리뷰 내역이 없어요
                            </h3>
                            <p class="textbox__description">
                                 다양한 숙소를 이용하고 <br>
                                리뷰를 작성해 보세요!
                            </p>
                            <div class="textbox__button">
                                <div class="row">
                                    <button type="button" class="btn btn-primary purple">
                                        숙소 보러가기
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			<!-- //content -->
		</div>
	</div>
</template>